import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { FaXing } from "@react-icons/all-files/fa/FaXing"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaHome } from "@react-icons/all-files/fa/FaHome"
import { BsFillCursorFill } from "@react-icons/all-files/bs/BsFillCursorFill"
import { MdEmail } from "@react-icons/all-files/md/MdEmail"

import CrosshairTile from "./crosshairTile"
import CallToActionButton from "./callToActionButton"
import BackButton from "./backButton"
import { validateUrl, validateEmailLink, getJobwallLink } from "../helpers/formatter"

import * as foerdermitgliedStyles from "./foerdermitgliedComponent.module.scss"

function FordermitgliedComponent(props) {
    const {foerdermitgliedLogo} = props
    return(
        <div className="content">
            <CrosshairTile>
                <div className={`columns ${foerdermitgliedStyles.imageColumn}`}>
                    <div className={`column is-12 pt-0 ${foerdermitgliedStyles.imageWrapper}`}>
                            <Img style={{ height: "100%", width: "100%", transform: "scale(1.5)"}} 
                            imgStyle={{ objectFit: "contain" }} 
                            fixed={props.image.childImageSharp.fixed} alt=""/>
                            
                    </div>
                    {foerdermitgliedLogo && props.isFoerdermitglied && <div className={`column is-12 pt-0 ${foerdermitgliedStyles.logoWrapper}`}>
                        <Img style={{ height: "100%", width: "100%"}} 
                            imgStyle={{ objectFit: "contain" }} 
                            fixed={foerdermitgliedLogo.childImageSharp.fixed} alt=""/>
                            
                    </div>}
                </div>
            </CrosshairTile>
            <div className="columns is-multiline mt-5">
                <div className={`column is-6 pr-5 pl-5 ${foerdermitgliedStyles.info}`}>
                    <div className={`mb-2 ${props.displayBackButton ? foerdermitgliedStyles.backbuttonTitleWrapper : ""}`}>
                        {props.displayBackButton && <div className="mr-6"><BackButton to={props.backButtonDestination}/></div>}
                        <div className={foerdermitgliedStyles.title}><p>{props.title}</p></div>
                    </div>
                    <div className={foerdermitgliedStyles.companyInfos}>
                        <div>
                            <div><p>{props.street}</p></div>
                            <div><p>{props.city}</p></div>
                        </div>
                        {(props.street || props.city) && <div className={foerdermitgliedStyles.companyInfosSymbol}><BsFillCursorFill /></div>}
                    </div>
                    <div className={`pt-4 pb-4`}>
                        {props.homepage && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.homepage)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>{props.homepage}</p> <span className={foerdermitgliedStyles.linkIcon}><FaHome/></span>
                            </a>
                        </div>}
                        {props.email && <div className={foerdermitgliedStyles.link}>
                            <a href={validateEmailLink(props.email)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>{props.email}</p> <span className={foerdermitgliedStyles.linkIcon}><MdEmail/></span>
                            </a>
                        </div>}
                        {props.twitterlink && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.twitterlink)} target="_blank" rel="noreferrer"> 
                                <p className={foerdermitgliedStyles.linkName}>Twitter</p> <span className={foerdermitgliedStyles.linkIcon}><FaTwitter /></span>
                            </a>
                        </div>}
                        {props.facebooklink && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.facebooklink)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>Facebook</p> <span className={foerdermitgliedStyles.linkIcon}><FaFacebookF /></span>
                            </a>
                        </div>}
                        {props.linkedinlink && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.linkedinlink)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>LinkedIn</p> <span className={foerdermitgliedStyles.linkIcon}><FaLinkedinIn /></span>
                            </a>
                        </div>}
                        {props.xinglink && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.xinglink)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>Xing</p> <span className={foerdermitgliedStyles.linkIcon}><FaXing /></span>
                            </a>
                        </div>}
                        {props.instagramlink && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.instagramlink)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>Instagram</p> <span className={foerdermitgliedStyles.linkIcon}><FaInstagram /></span>
                            </a>
                        </div>}
                        {props.youtubelink && <div className={foerdermitgliedStyles.link}>
                            <a href={validateUrl(props.youtubelink)} target="_blank" rel="noreferrer">
                                <p className={foerdermitgliedStyles.linkName}>YouTube</p> <span className={foerdermitgliedStyles.linkIcon}><FaYoutube /></span>
                            </a>
                        </div>}
                    </div>
                </div>
                <div className={`column is-6 pr-5 pl-5`}>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{ __html: props.html }}></div>
                    </div>
                </div>
                {props.displayJobButton && <div className={`column is-12 pt-3 pb-3 mt-2`}>
                    <Link to={getJobwallLink(props.jobwalllink)}>
                        <CallToActionButton text="INTERESSIERT? FINDE DIE PASSENDEN JOBS AUF UNSERER JOBWALL" />
                    </Link>
                </div>}
            </div>
        </div>
    )
}

export default FordermitgliedComponent