import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"
import FordermitgliedComponent from "../components/foerdermitgliedComponent"

const FoerdermitgliedTemplate = (props) => {
    const foerdermitglied = props.data.markdownRemark
    const foerdermitgliedImage = props.data.foerdermitgliedImage.edges[0].node
    let displayJobButton = true
    if(props.location.pathname.includes("preissponsoren")) {
        displayJobButton = false
    }
    const siteTitle = "Foerdermitglieder"
    return (
        <Layout location={props.location} title={siteTitle}>
        <SEO title={foerdermitglied.frontmatter.title} />
            <ContentContainer>
            <FordermitgliedComponent image={foerdermitglied.frontmatter.image} homepage={foerdermitglied.frontmatter.homepage}
                title={foerdermitglied.frontmatter.title} facebooklink={foerdermitglied.frontmatter.facebooklink}
                linkedinlink={foerdermitglied.frontmatter.linkedinlink} city={foerdermitglied.frontmatter.city}
                street={foerdermitglied.frontmatter.street} xinglink={foerdermitglied.frontmatter.xinglink} email={foerdermitglied.frontmatter.email}
                instagramlink={foerdermitglied.frontmatter.instagramlink} youtubelink={foerdermitglied.frontmatter.youtubelink} 
                twitterlink={foerdermitglied.frontmatter.twitterlink} html={foerdermitglied.html} backButtonDestination="/partner/foerdermitglieder"
                foerdermitgliedLogo={foerdermitgliedImage} displayBackButton displayJobButton={displayJobButton} jobwalllink={foerdermitglied.frontmatter.jobwalllink}
            />
            </ContentContainer>
        </Layout>
    )
}

export default FoerdermitgliedTemplate

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {
            slug: {
                eq: $slug
            }
        }) {
            frontmatter {
                title
                street
                city
                homepage
                email
                facebooklink
                twitterlink
                linkedinlink
                xinglink
                instagramlink
                youtubelink
                jobwalllink
                image {
                    childImageSharp {
                      fixed(width: 800) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                }
            }
            html
        }
        foerdermitgliedImage: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/foerdermitgliedLogo/"}}) {
            edges {
              node {
                id
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed
                      originalName
                    }
                }
              }
            }
          }
    }
`